.App {
    text-align: center;
}

.App-header {
    background-color: #000;
    min-height: 100vh;
    display: flex;

    padding-top: 10%;
    justify-content: center;
    /*font-size: calc(10px + 2vmin);*/
    color: white;
}

.App-link {
    /*color: #61dafb;*/
}

.App {
    text-align: center;
}

body {
    font: 12px/18px "Lucida Grande", "Lucida Sans Unicode", Helvetica, Arial, Verdana, sans-serif;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    /*-webkit-font-smoothing: subpixel-antialiased;*/
    color: #666;
    line-height: 11px;
    /*-webkit-backface-visibility: hidden;*/
    /*backface-visibility: hidden;*/
    overflow-x: hidden;
}

.main-title {
    color: #e3e3e3;
    font-size: 30px;
    line-height: 30px;
}

.sub-title {
    color: #919191;
    font-size: 20px;
    line-height: 20px;
}

.specialization {
    /*color: #396dbf;*/
    color: #54c8ff;
}

.bordered-image {
    background-color: #F5F5F5;
    border: 1px solid #797979;
    border-radius: 50% 50% 50% 50%;
    padding: 1px;
    width: 300px;
}
